import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import {
//   getAllCamps,
//   deleteCampAction
// } from "./../../../store/actions/preferencesActions";
import { getAllGideons } from './../../../store/actions/usersActions';
import Spinner from './../../helpers/Spinner';
import Message from './../../helpers/Message';
import { hasPermission } from './../../helpers/functions/functions';
import { setPermissionsErrors } from './../../../store/actions/authActions';
import $ from 'jquery';

class ViewGideons extends Component {
  state = {
    // gideons: null,
    initdep: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ initdep: true });
    // const { spinner, message, status } = this.props;

    if (
      !hasPermission({ mod: 'Preferences', action: 'VIEW_MEMBERSHIP' }) ||
      !hasPermission({ mod: 'Preferences', action: 'VIEW_MEMBER' })
    ) {
      setPermissionsErrors();
      this.props.history.push('/');
    } else {
      this.props.getAllGideons();
    }
  }
  initDependency = () => {
    if (this.state.initdep) {
      const old_element = document.querySelector(
        "script[src='/js/content.js']",
      );
      const new_element = document.createElement('script');
      new_element.src = `/js/content.js`;
      new_element.async = true;
      if (old_element) {
        old_element.replaceWith(new_element);
      } else {
        document.body.appendChild(new_element);
      }
      this.setState({ initdep: false });
    }
  };
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.gideons !== prevState.gideons) {
  //     return {
  //       gideons: nextProps.gideons,
  //     };
  //   }
  //   return null;
  // }
  // getMemberStatus = (status) => {
  //   if (status === "GOOD") {
  //     return "GOOD STANDING";
  //   } else if (status === "TRANS") {
  //     return "TRANSFERED";
  //   } else if (status === "DELI") {
  //     return "DELIQUENT";
  //   } else if (status === "DROP") {
  //     return "DROPPED";
  //   } else if (status === "ACTI") {
  //     return "ACTIVE";
  //   } else if (status === "DELISTED") {
  //     return "DELISTED";
  //   } else if (status === "EXIT") {
  //     return "EXIT";
  //   }
  //   return "";
  // };
  dateConv = (date) => {
    if (date === null || date === '') {
      return null;
    }
    return new Date(date).toDateString();
  };
  render() {
    const { spinner, message, status, gideons } = this.props;
    if (!spinner && status !== 'error') {
      this.initDependency();
    }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Membership</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">View Gideons</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">View Gideons</h3>
              <div className="card-tools">
                <Link to="/membership/gideons/create">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-plus-circle mr-1"></i>
                    ADD NEW GIDEON
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              <div key="uniqueKey">
                <table
                  id="example"
                  className="table table-hover table-bordered table-striped"
                  style={{
                    width: '100%',
                  }}
                >
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Last Name</th>
                      <th>First Name</th>
                      <th>Other Name</th>
                      <th>Member Id</th>
                      <th>Spouse Id</th>
                      <th>Spouse Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Recruitment Method</th>
                      <th>Renewal Type</th>
                      <th>Camp</th>
                      <th>Area</th>
                      <th>Region</th>
                      <th>State</th>

                      <th>Status</th>
                      <th>Due Date</th>
                      <th>Accepted Date</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gideons &&
                      gideons.map((gideon, index) => {
                        return (
                          <tr key={gideon.id}>
                            <td>{index + 1}</td>

                            <td>{gideon.last_name}</td>
                            <td>{gideon.first_name}</td>
                            <td>{gideon.other_name || '--'}</td>
                            <td>{gideon.login_id || '--'}</td>
                            <td>{gideon.aux ? gideon.aux.login_id : '--'}</td>
                            <td>
                              {' '}
                              {gideon.aux
                                ? `${gideon.aux.first_name} ${gideon.aux.last_name}`
                                : '--'}
                            </td>

                            {/* <td>{gideon.phone.trim() || "--"}</td>
                              <td>{gideon.email || "--"}</td>
                              <td>{gideon.camp?.name || "--"}</td>
                              <td>{gideon.camp.area?.name || "--"}</td>
                              <td>{gideon.camp.area.region?.name || "--"}</td>
                              <td>
                                {gideon.camp.area.region.state?.name || "--"}
                              </td> */}
                            <td>{gideon.phone ? gideon.phone.trim() : '--'}</td>
                            <td>{gideon.email || '--'}</td>
                            <td>{gideon.recruitment || '--'}</td>
                            <td>
                              {' '}
                              <span className="text-uppercase">
                                {gideon.membership_type || '--'}
                              </span>{' '}
                            </td>
                            {/*--------modifications by Macdonald-------------*/}
                            <td>{gideon.camp ? gideon.camp.name : '--'}</td>
                            <td>
                              {gideon.camp ? gideon.camp.area.name : '--'}
                            </td>
                            <td>
                              {gideon.camp
                                ? gideon.camp.area.region.name
                                : '--'}
                            </td>
                            <td>
                              {gideon.camp
                                ? gideon.camp.area.region.state.name
                                : '--'}
                            </td>
                            {/*--------------End Modifications------------------ */}
                            <td> {gideon.status} </td>
                            <td> {this.dateConv(gideon.expiry_date)} </td>
                            <td> {this.dateConv(gideon.accepted_date)} </td>
                            <td>
                              <div
                                style={{
                                  overflow: 'visible',
                                  position: 'relative',
                                  width: '140px',
                                }}
                              >
                                <center>
                                  <Link
                                    to={`/membership/gideons/edit/${gideon.id}`}
                                    className="btn btn-sm btn-success btn-icon mr-1"
                                    title="Edit Gideon"
                                  >
                                    <i className="fas fa-edit text-white"></i>
                                  </Link>

                                  <Link
                                    to={`/membership/gideons/${gideon.id}`}
                                    className="btn btn-sm btn-info btn-icon mr-1"
                                    title="View More Details"
                                  >
                                    <i className="fas fa-eye text-white"></i>
                                  </Link>
                                </center>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}

        <div
          className="modal fade"
          id="RemarkGidModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="RemarkGidModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="RemarkGidModalLabel">
                  Delist Member
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <label htmlFor="member_name">Member</label>
                <input
                  type="text"
                  id="member_name"
                  className="form-control"
                  defaultValue={this.state.name}
                  placeholder="Member Name"
                />

                <br />

                <label htmlFor="remark">Remark</label>
                <textarea
                  rows={3}
                  id="remark"
                  name="remark"
                  value={this.state.remark}
                  onChange={this.handleChange}
                  className="form-control"
                  placeholder="Provide reasons for delisting member"
                >
                  {this.state.remark}
                </textarea>

                <span className="text-danger">
                  {this.state.error == 1
                    ? 'Error! Kindly fill the remark field'
                    : ''}
                </span>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  id="closeModal"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.triggerDelist()}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gideons: state.users.gideons,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllGideons: () => dispatch(getAllGideons()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewGideons);
