import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllActiveMembers } from '../../../store/actions/usersActions';
import Spinner from './../../helpers/Spinner';
import Message from './../../helpers/Message';
// import { hasPermission } from "./../../helpers/functions/functions";
import { setPermissionsErrors } from '../../../store/actions/authActions';
import { storage_type, user_auth_details, dehash } from './../../../config';

class ViewAllActiveMembers extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.initdepref = React.createRef();
  }
  state = {
    // members: null,
    initdep: false,
  };
  componentDidMount() {
    // this.setState({ initdep: true });
    this.initdepref.current = true;
    const { setPermissionsErrors } = this.props;
    const user = JSON.parse(
      dehash(`${storage_type.getItem(user_auth_details)}`),
    );
    if (user.type !== 'S') {
      setPermissionsErrors();
      this.props.history.push('/');
    } else {
      this.props.getAllActiveMembers();
    }
  }
  initDependency = () => {
    if (this.initdepref.current) {
      // alert("here");
      const old_element = document.querySelector(
        "script[src='/js/content.js']",
      );
      const new_element = document.createElement('script');
      new_element.src = `/js/content.js`;
      new_element.async = true;
      if (old_element) {
        old_element.replaceWith(new_element);
      } else {
        document.body.appendChild(new_element);
      }

      this.initdepref.current = false;
      // this.setState({ initdep: false });
    }
  };
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.members !== prevState.members) {
  //     return {
  //       members: nextProps.members,
  //     };
  //   }
  //   return null;
  // }
  // getMemberStatus = (status) => {
  //   if (status === "GOOD") {
  //     return "GOOD STANDING";
  //   } else if (status === "TRANS") {
  //     return "TRANSFERED";
  //   } else if (status === "DELI") {
  //     return "DELIQUENT";
  //   } else if (status === "DROP") {
  //     return "DROPPED";
  //   } else if (status === "ACTI") {
  //     return "ACTIVE";
  //   } else if (status === "EXIT") {
  //     return "EXIT";
  //   }
  //   return "";
  // };
  deleteCamp = (e, id) => {
    const { deleteCampAction } = this.props;
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete this camp?')) {
      deleteCampAction(id);
    }
  };
  dateConv = (date) => {
    if (date === null || date === '') {
      return null;
    }
    return new Date(date).toDateString();
  };
  render() {
    const { spinner, message, status, active_members } = this.props;
    if (!spinner && status !== 'error') {
      // this.setState({ initdep: true });
      // this.initdepref.current = true;
      // this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PERSONAL</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Active Members</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">Active Members</h3>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}

              {active_members.length ? (
                <div key="uniqueKey">
                  <table
                    id="example"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: '100%',
                    }}
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>Member Id</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Recruitment Method</th>
                        <th>Camp</th>
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>type</th>
                        <th>Renewal type</th>
                        <th>Status</th>
                        <th>Expiry Date</th>
                        <th>Accepted Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {active_members?.map((camp, index) => {
                        return (
                          <tr key={camp.id}>
                            <td>{index + 1}</td>
                            <td>
                              {camp.last_name} {camp.first_name}{' '}
                              {camp.other_name}
                            </td>
                            <td>{camp.login_id}</td>
                            <td>{camp.phone ? camp.phone.trim() : '--'}</td>
                            <td>{camp.email || '--'}</td>
                            <td>{camp.recruitment || '--'}</td>
                            <td>{camp.camp?.name || '--'}</td>
                            <td>{camp.camp?.area?.name || '--'}</td>
                            <td>{camp.camp?.area?.region?.name || '--'}</td>
                            <td>
                              {camp.camp?.area?.region?.state?.name || '--'}
                            </td>
                            <td>
                              {camp.type === 'G' ? 'GIDEON' : 'AUXILLIARY'}
                            </td>
                            <td>{camp.membership_type}</td>
                            <td>{camp.status}</td>
                            <td>{this.dateConv(camp.expiry_date)}</td>
                            <td>
                              {this.dateConv(camp.accepted_date) || 'N/A'}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {this.initDependency()}
                </div>
              ) : null}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    active_members: state.users.active_members,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllActiveMembers: () => dispatch(getAllActiveMembers()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewAllActiveMembers);
