import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getAreaMemberReport,
  resetReportState,
} from './../../../../store/actions/reportActions';
import {
  getAllAreas,
  getAllCamps,
  selectCamp,
} from '../../../../store/actions/preferencesActions';

import Spinner from './../../../helpers/Spinner';
import Message from './../../../helpers/Message';
import classnames from 'classnames';
import MiniSpinner from './../../../helpers/MiniSpinner';
import $ from 'jquery';
// import _ from "underscore";
// import { hasPermission } from "./../../helpers/functions/functions";
import { hasPermission } from './../../../helpers/functions/functions';
import { setPermissionsErrors } from '../../../../store/actions/authActions';
import { BASE_URL, token, storage_type } from '../../../../config';

class AreaMemberReportHQ extends Component {
  state = {
    // areas: null,
    mem_area: null,
    area_id: '',
    camp_id: '',
    areas: null,
    camps: null,
    s_campFromArea: null,
    // arranged_data: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: 'Reports',
        action: 'VIEW_REPORTS',
      }) ||
      !hasPermission({
        mod: 'Reports',
        action: 'VIEW_AREA_MEMBERSHIP_REPORT_HQ',
      })
    ) {
      setPermissionsErrors();
      this.props.history.push('/');
    }
    document.title =
      'The Gideon International In Nigeria (Area Membership Report)';
    this.initDependency();
    // const { getAreaMemberReport } = this.props;
    // getAreaMemberReport(1, "12/12/2020", "15/12/2020");
    // this.props.setAll();
    this.props.getAllAreas();
    // this.props.getAllCamps();
  }
  initDependency = () => {
    // alert("here");
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement('script');
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.areas !== prevState.areas ||
      nextProps.camps !== prevState.camps ||
      nextProps.s_campFromArea !== prevState.s_campFromArea ||
      nextProps.mem_area !== prevState.mem_area
    ) {
      // const arry = [];
      // const temp_mem_area = [...nextProps.mem_area];
      // if (temp_mem_area.length) {
      //   const camp_group = _.groupBy(temp_mem_area, "area");
      //   for (let [key, value] of Object.entries(camp_group)) {
      //     arry.push({
      //       area: key,
      //       fund: _.groupBy(value, "purpose"),
      //     });
      //   }
      //   // console.log(arry);
      //   // this.updateState(arry);
      // }

      return {
        // areas: nextProps.areas,
        camps: nextProps.camps,
        mem_area: nextProps.mem_area,
        areas: nextProps.areas,
        s_campFromArea: nextProps.s_campFromArea,
      };
    }
    return null;
  }
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === 'error') {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === 'error') {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  dateConv = (date) => {
    return date ? new Date(date).toDateString() : null;
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const from_date = $('#from_date').val();
    const to_date = $('#to_date').val();
    // const camp = $(".memberselect22").val();

    const area = this.state.area_id;
    const camp = this.state.camp_id;
    // alert(area);
    document.title = `The Gideon International In Nigeria (Area Membership Report ${from_date}---${to_date})`;
    this.setState(
      {
        from_date,
        to_date,
        // id
      },
      () => {
        this.props.getAreaMemberReport(from_date, to_date, area, camp);
      },
    );
  };

  // arrangeReport = () => {};
  // updateState = arry => {
  //   const dd = arry;
  //   setTimeout(() => {
  //     this.setState({ new_arry: dd });
  //   }, 1000);
  // };
  setDownload = (e) => {
    e.preventDefault();
    $('.fund_table').attr('id', 'role_table22');
    this.initDependency();
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };
  handleDownload = (e) => {
    e.preventDefault();
    const from = $('#from_date').val();
    const to = $('#to_date').val();
    const area = this.state.area_id;
    const tok = storage_type.getItem(token);
    const download = 1;
    const url = `${BASE_URL}/reports/areas/funds?from=${from}&to=${to}&area=${area}&download=${download}&token=${tok}`;
    window.open(url, '_blank');
  };

  componentWillUnmount() {
    this.props.resetReportState();
  }

  render() {
    const { spinner, message, status, preference_spinner } = this.props;
    if (!spinner && status !== 'error') {
      this.initDependency();
    }
    // if (this.state.mem_area) {
    //   this.arrangeReport();
    // }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>REPORT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Membership Report HQ
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Membership Report HQ
              </h3>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: '3000px' }}>
                  <Spinner />
                </div>
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row  ">
                  <div className="col-12 text-center">
                    <p>
                      Ensure your selected date range falls within one Gideon
                      year.
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="from_date">
                        From Date <span className="text-danger">*</span>{' '}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          'is-invalid': this.hasErrorFor('from_date'),
                          'form-control': true,
                        })}
                        id="from_date"
                        name="from_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor('from_date')}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="to_date">
                        To Date <span className="text-danger">*</span>{' '}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          'is-invalid': this.hasErrorFor('to_date'),
                          'form-control': true,
                        })}
                        id="to_date"
                        name="to_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor('to_date')}
                    </div>
                  </div>
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="camp_id">
                        Camp <span className="text-danger">*</span>{" "}
                      </label>
                      <div className="d-flex">
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("camp_id"),
                            "form-control": true,
                            memberselect22: true
                          })}
                          placeholder="Select a Camp"
                          onChange={this.handleChange}
                          value={this.state.camp_id}
                          id="camp_id"
                          name="camp_id"
                          style={{
                            width: "100%"
                          }}
                        >
                          <option value="">--Select a camp--</option>
                          {this.state.camps &&
                            this.state.camps.map((camp, index) => (
                              <option key={index} value={camp.id}>
                                {camp.name} -- {camp.area.name} --{" "}
                                {camp.area.region.name} --{" "}
                                {camp.area.region.state.name}
                              </option>
                            ))}
                        </select>
                        {preference_spinner ? <MiniSpinner /> : null}
                      </div>
                      {this.renderErrorFor("camp_id")}
                    </div>
                  </div> */}
                  <div className=" col-sm-4">
                    <label>Area</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          'is-invalid': this.hasErrorFor('area_id'),
                          'form-control selected_area': true,
                        })}
                        placeholder="Select a Role"
                        onChange={this.handleChange}
                        id="area_id"
                        value={this.state.area_id}
                        // defaultValue={this.state.area_id}
                      >
                        <option value="">--Select an Area--</option>
                        {this.state.areas &&
                          this.state.areas.map((area, index) => (
                            <option key={index} value={area.id}>
                              {area.name}--{area.region.name}--
                              {area.region.state.name}
                            </option>
                          ))}
                      </select>
                      {preference_spinner ? <MiniSpinner /> : null}
                    </div>
                    {this.renderErrorFor('area_id')}
                  </div>

                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="camp_id">Camp</label>
                      <div className="d-flex">
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("camp_id"),
                            "form-control": true,
                            memberselect22: true,
                          })}
                          placeholder="Select a Camp"
                          onChange={this.handleChange}
                          value={this.state.camp_id}
                          id="camp_id"
                          name="camp_id"
                          style={{
                            width: "100%",
                          }}
                        >
                          <option value="">--Select a camp--</option>
                          {this.state.camps &&
                            this.state.camps.map((camp, index) => (
                              <option key={index} value={camp.id}>
                                {camp.name} -- {camp.area.name} --{" "}
                                {camp.area.region.name} --{" "}
                                {camp.area.region.state.name}
                              </option>
                            ))}
                        </select>
                        {preference_spinner ? <MiniSpinner /> : null}
                      </div>
                      {this.renderErrorFor("camp_id")}
                    </div>
                  </div> */}
                </div>
                <div className="row mb-3">
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    disabled={spinner ? true : false}
                  >
                    Generate
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>
                  {/* {this.state.mem_area.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}

                  {/* <button
                    type="button"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    onClick={this.handleDownload}
                  >
                    Download
                  </button> */}
                </div>
              </form>

              {this.state.mem_area.length && !spinner ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: '100%',
                      marginTop: '5px',
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Camp</th>
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Total Gideons </th>
                        <th>Total Auxilliaries </th>
                        <th>Active Gideons </th>
                        <th>Active Auxilliaries </th>
                        <th>Dropped Gideons </th>
                        <th>Dropped Auxilliaries </th>
                        <th>New Gideons</th>
                        <th>New Auxilliaries </th>
                        <th>Reinstated Gideons </th>
                        <th>Reinstated Auxilliaries </th>
                        <th>Delisted Gideons </th>
                        <th>Delisted Auxilliaries </th>
                        <th>With the Lord Gideons </th>
                        <th>With the Lord Auxilliaries </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.mem_area &&
                        this.state.mem_area.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.camp}</td>
                                <td>{item.area}</td>
                                <td>{item.region}</td>
                                <td>{item.state}</td>
                                <td>
                                  {(parseFloat(item.active.gideon) || 0) +
                                    (parseFloat(item.dropped.gideon) || 0) +
                                    (parseFloat(
                                      item.new_members.total_gideon,
                                    ) || 0) +
                                    (parseFloat(item.reinstated.gideon) || 0) +
                                    (parseFloat(item.actual_delisted.gideon) ||
                                      0) +
                                    (parseFloat(item.cal_rip.gideon) || 0)}
                                </td>
                                <td>
                                  {(parseFloat(item.active.aux) || 0) +
                                    (parseFloat(item.dropped.aux) || 0) +
                                    (parseFloat(item.new_members.total_aux) ||
                                      0) +
                                    (parseFloat(item.reinstated.aux) || 0) +
                                    (parseFloat(item.actual_delisted.aux) ||
                                      0) +
                                    (parseFloat(item.cal_rip.aux) || 0)}
                                </td>
                                <td>{item.active.gideon || 0}</td>
                                <td>{item.active.aux || 0}</td>
                                <td>{item.dropped.gideon || 0}</td>
                                <td>{item.dropped.aux || 0}</td>
                                <td>{item.new_members.total_gideon || 0}</td>
                                <td>{item.new_members.total_aux || 0}</td>
                                <td>{item.reinstated.gideon || 0}</td>
                                <td>{item.reinstated.aux || 0}</td>
                                <td>{item.actual_delisted.gideon || 0}</td>
                                <td>{item.actual_delisted.aux || 0}</td>
                                <td>{item.cal_rip.gideon || 0}</td>
                                <td>{item.cal_rip.aux || 0}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}

                      <tr>
                        <th>Total</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          {(this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.active.gideon)
                                  ? parseFloat(obj.active.gideon)
                                  : 0)
                              );
                            }, 0)) +
                            (this.state.mem_area &&
                              this.state.mem_area.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.dropped.gideon)
                                    ? parseFloat(obj.dropped.gideon)
                                    : 0)
                                );
                              }, 0)) +
                            (this.state.mem_area &&
                              this.state.mem_area.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.new_members.total_gideon)
                                    ? parseFloat(obj.new_members.total_gideon)
                                    : 0)
                                );
                              }, 0)) +
                            (this.state.mem_area &&
                              this.state.mem_area.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.reinstated.gideon)
                                    ? parseFloat(obj.reinstated.gideon)
                                    : 0)
                                );
                              }, 0))}
                        </th>
                        <th>
                          {(this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.active.aux)
                                  ? parseFloat(obj.active.aux)
                                  : 0)
                              );
                            }, 0)) +
                            (this.state.mem_area &&
                              this.state.mem_area.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.dropped.aux)
                                    ? parseFloat(obj.dropped.aux)
                                    : 0)
                                );
                              }, 0)) +
                            (this.state.mem_area &&
                              this.state.mem_area.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.new_members.total_aux)
                                    ? parseFloat(obj.new_members.total_aux)
                                    : 0)
                                );
                              }, 0)) +
                            (this.state.mem_area &&
                              this.state.mem_area.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.reinstated.aux)
                                    ? parseFloat(obj.reinstated.aux)
                                    : 0)
                                );
                              }, 0))}
                        </th>

                        <th>
                          {this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.active.gideon)
                                  ? parseFloat(obj.active.gideon)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.active.aux)
                                  ? parseFloat(obj.active.aux)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.dropped.gideon)
                                  ? parseFloat(obj.dropped.gideon)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.dropped.aux)
                                  ? parseFloat(obj.dropped.aux)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.new_members.total_gideon)
                                  ? parseFloat(obj.new_members.total_gideon)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.new_members.total_aux)
                                  ? parseFloat(obj.new_members.total_aux)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.reinstated.gideon)
                                  ? parseFloat(obj.reinstated.gideon)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.reinstated.aux)
                                  ? parseFloat(obj.reinstated.aux)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.actual_delisted.gideon)
                                  ? parseFloat(obj.actual_delisted.gideon)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_area &&
                            this.state.mem_area.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.actual_delisted.aux)
                                  ? parseFloat(obj.actual_delisted.aux)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.reports.spinner,
    message: state.reports.message,
    status: state.reports.status,
    errors: state.reports.errors,
    mem_area: state.reports.mem_area,
    camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
    areas: state.preferences.areas,
    user_area: state.preferences.user_area,
    s_campFromArea: state.preferences.s_campFromArea,
    // campsFromArea: state.preferences.campsFromArea,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAreaMemberReport: (from, to, area) =>
      dispatch(getAreaMemberReport(from, to, area)),
    getAllCamps: () => dispatch(getAllCamps()),
    getAllAreas: () => dispatch(getAllAreas()),
    selectCamp: (id) => dispatch(selectCamp(id)),
    // setAll: () => dispatch(setAll()),
    // setAllCampFromArea: () => dispatch(setAllCampFromArea()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetReportState: () => resetReportState(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AreaMemberReportHQ);
